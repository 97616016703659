/** Login Actions  */

import {
    actionCreator,
    loginActionTypes,
    jsonApiHeader
} from '../constants/apiConstants';

import history from '../../../history.js';
import { API_URL } from '../../../actions/utilAction';
import axios from 'axios';
// import toastr from 'toastr';
import * as constant from '../../../actions/constant';
import { toasterMessage } from "../../../actions/util";
import jwt from 'jsonwebtoken';


let access_token = localStorage.access_token;
// let access_token = sessionStorage.access_token;

export function loginAction(authObj, isChecked, callback) {
    return (dispatch) => {
        axios.post(API_URL + constant.USER_LOGIN, authObj, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            if (response.data.status === 200) {
                dispatch(actionCreator(loginActionTypes.post_login.SUCCESS,
                    { errorMessage: null, access_token: response.data.data.userToken, authData: response.data.data.userData }));
                const userToken = jwt.sign(
                    authObj,
                    'DEMO@xyz',
                    { expiresIn: "1d" }
                );
                localStorage.setItem('access_token', response.data.data.userToken);
                // localStorage.setItem('userData', JSON.stringify(response.data.data.userData));
                localStorage.setItem('buffer', userToken);
                localStorage.setItem('isChecked', isChecked);
                // localStorage.setItem("userType",response.data.data.userData.userType)
                // window.sessionStorage.setItem("access_token",response.data.data.userToken);
                // window.sessionStorage.setItem("userData", JSON.stringify(response.data.data.userData));
                // window.sessionStorage.setItem("isChecked", isChecked);
                if (response.data.data.userData.userType === 1) {
                    history.push(constant.LIST_CATEGORY);
                }
                else {
                    history.push(constant.DASHBOARD);
                }
                toasterMessage("success", response.data.message);
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
            return callback(error.response.data.error);
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
            return callback('err');
        })
    };
}


export function getUserDetails() {
    return (dispatch) => {
        axios.get(API_URL + constant.GET_USER_DETAILS, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            if (response.data.status === 200) {
                // toasterMessage("success", response.data.message);
                dispatch(actionCreator(loginActionTypes.get_user_details.SUCCESS,
                    { userDetails: response.data.data }));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
        })
    };
}

export function editUser(authObj,callback) {
    // return false
    return (dispatch) => {
        axios.put(API_URL + constant.EDIT_USER_DETAILS, authObj, {
            headers: jsonApiHeader(access_token, 'application/json')
        }).then(function (response) {
            // console.log("response", response)
            if (response.data.status === 200) {
                toasterMessage("success", response.data.message)
                history.push(constant.DASHBOARD);
                dispatch(actionCreator(loginActionTypes.get_user_details.SUCCESS,
                    { userDetails: response.data.data }));
            }
        }).catch((error) => {
            toasterMessage("error", error.response.data.error);
            callback(error.response.data.error)
            if (error.response.status === 300) {
                toasterMessage("error", error.response.data.error);
                callback(error.response.data.error)
            }
        }).catch(() => {
            toasterMessage('error', 'Something bad happened')
            callback("err")

        })
    }
}
